import React from "react"
import { Link } from 'gatsby'

class NavMobile extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            showMobile: false,
            navChild: ''
        }
    }

    handleClose = event => {
        event.preventDefault();
		this.setState({
			showMobile: false,
        })
    }
    
    handleOpen = event => {
        event.preventDefault();
		this.setState({
			showMobile: true,
        })
    }

    handleNavChild = event => {
        event.preventDefault();
        let target = event.target.getAttribute('data-id');
        if (this.state.navChild === target) { 
            this.setState({
                navChild: ''
            });
        } else {
            this.setState({
                navChild: target
            })
        }
    }

    render () {
        return (
            <div className="layout">
                <div className={this.state.showMobile ? 'nav__mobile--show' : 'nav__mobile--hide'}>
                    <div className="nav__mobile--overlay"></div>
                    <div className="nav__mobile--close">
                        <button className="nav__mobile--close__link" onClick={this.handleClose}>Close ×</button>
                    </div>
                    <div className="nav__mobile">
                        <nav className="nav__mobile--content">
                            <ul>
                                <li><a href="https://bookings.gettimely.com/andrewwaldegraveltd/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F153135%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue" rel="nofollow noopener">Book Online</a></li>
                                <li><Link to='/services/' activeClassName="active">Services</Link></li>
                                <li><Link to='/products/' activeClassName="active">Products</Link></li>
                                <li><Link to='/about/' activeClassName="active">About</Link></li>
                                <li><Link to='/blog/' activeClassName="active">Blog</Link></li>
                                <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="layout logo__gradient">
                    <div className="layout__container">
                        <div className="layout__wide">
                            <div className="grid grid__1-3 grid__gap--48 align__items">
                                <div className="logo center">
                                    <Link to='/'><img src="/img/logo.svg" alt="The Green Room Hair Studio" /></Link>
                                </div>
                                <div className="nav nav__off">
                                    <ul className="sf__menu">
                                        <li><Link to='/services/' activeClassName="active">Services</Link></li>
                                        <li><Link to='/products/' activeClassName="active">Products</Link></li>
                                        <li><Link to='/about/' activeClassName="active">About</Link></li>
                                        <li><Link to='/blog/' activeClassName="active">Blog</Link></li>
                                        <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                                        <li className="phone"><a href="tel:021889012" title="021 889 012" rel="nofollow noopener">Ph: 021 889 012</a></li>
                                    </ul>
                                </div>
                                <div className="nav__top nav__mobile__on">
                                    <ul>
                                        <li className="nav__button"><button title="Menu" onClick={this.handleOpen} className="nav__hamburger--toggle">&#9776; Menu</button></li>
                                        <li className="phone"><a href="tel:021889012" title="021 889 012" rel="nofollow noopener">Ph: 021 889 012</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (data) => (
    <NavMobile data={data.allMarkdownRemark} />
)
