import React from "react"
import { Link } from 'gatsby'

export default () => {
    return (
        <div className="layout dark">
            <div className="layout__container">
                <div className="layout__wide copyright center">
                    <p>&copy; 2023 &nbsp; • &nbsp; <Link to='/terms/'>Terms</Link> &nbsp; • &nbsp; <Link to='/privacy/'>Privacy</Link> &nbsp; • &nbsp; 31 Davis Crescent, Newmarket &nbsp; • &nbsp; <a href="https://www.facebook.com/TheGreenRoomHairStudio" rel="nofollow noreferrer" target="_blank">Facebook</a></p>
                </div>
            </div>
        </div>
    )
}